<template>
	<div class="management">
		<div class="management-T">
			<div class="management-TL">
				<Breadcrumb class="bre"></Breadcrumb>
				<div class="management-searchs">
					<!-- <inputs @inputs="inputserValue" :inputShow="true" inputPlace="请输入SN/名称查询"></inputs>
					<div class="management-tit-mai1-btn">
						<el-button type="danger" icon="el-icon-search">查询</el-button>
					</div> -->
				</div>
			</div>
			<div class="management-TR">
				<div class="management-searchsMai">
					<el-button type="primary" icon="el-icon-notebook-2" @click="instructionse()">说明</el-button>
					<el-button type="danger" v-permission="'Device:Add'" icon="el-icon-plus"
						@click="SetNew()">新增</el-button>
				</div>
			</div>
		</div>
		<div class="management-B">
			<div class="management-B-Nav">
				<div class="management-B-Navlist-mai">
					<div class="management-B-Navlist-tt">
						<div class="management-B-Navlist-tt-pir">
							<i class="el-icon-search"></i>
							<el-input placeholder="搜索名称、SN、物联网" v-model="pages.T_name" @input="inputser(pages.T_name)">
							</el-input>
						</div>
					</div>
				</div>
				<div class="management-B-Navlist" @scroll="scroll" v-if="NavList.length != 0">
					<div :class="navNum === index ? 'management-B-Navlist-mai1' : 'management-B-Navlist-mai'"
						v-for="item, index in NavList" :key="index" @click="navNumclick(index, item)">
						<div class="management-B-Navlist-mai-txt">
							<div class="management-B-Navlist-mai-txt0">
								<i class="el-icon-folder-opened" style="font-size: 20px;"></i>
							</div>
							<div class="management-B-Navlist-mai-txt1" :title="item.T_devName">
								{{ item.T_devName }}
							</div>
						</div>
						<div class="management-B-Navlist-mai-ico">
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
				</div>
				<div v-else style="color: #ccc;font-size: 12px;text-align: center;margin-top: 100px;">
					<i class="el-icon-search" style="font-size:40px;margin-bottom: 10px;"></i>
					<div>未查询到数据</div>
				</div>
			</div>
			<div class="management-B-Con">
				<div v-if="NavList.length == 0">
					<el-empty description="暂无可展示内容,请选择设备再查看"></el-empty>
				</div>
				<div style="padding: 20px;" v-else>
					<div class="manage-grid">
						<div class="manage-grid-title">主机信息</div>
						<div class="manage-grid-mai">
							<div class="manage-grid-mai-gr" :title="'主机名：' + descrData.T_devName">
								<div class="manage-grid-mai-grL">
									主机名：
								</div>
								<div class="manage-grid-mai-grR">
									{{ descrData.T_devName }}
								</div>
							</div>
							<div class="manage-grid-mai-gr" :title="'SN：' + descrData.T_sn">
								<div class="manage-grid-mai-grL">
									SN：
								</div>
								<div class="manage-grid-mai-grR">
									{{ descrData.T_sn }}
								</div>
							</div>
							<div class="manage-grid-mai-gr" :title="'主机型号：' + descrData.T_model">
								<div class="manage-grid-mai-grL">
									主机型号：
								</div>
								<div class="manage-grid-mai-grR">
									{{ descrData.T_model }}
								</div>
							</div>
							<div class="manage-grid-mai-gr" :title="'主机品名：' + descrData.T_ProductTypeName">
								<div class="manage-grid-mai-grL">
									主机品名：
								</div>
								<div class="manage-grid-mai-grR">
									{{ descrData.T_ProductTypeName }}
								</div>
							</div>

							<div class="manage-grid-mai-gr">
								<div class="manage-grid-mai-grL">
									连接状态：
								</div>
								<div class="manage-grid-mai-grR">
									<el-tag size="small"
										:type="descrData.T_online == 0 ? 'danger' : descrData.T_online == 1 ? '' : descrData.T_online == 2 ? 'danger' : 'danger'">
										{{ descrData.T_online == 0 ? '无网络' : descrData.T_online == 1 ? '在线' : descrData.T_online == 2 ? '离线' : '未知' }}
									</el-tag>
								</div>
							</div>
							<div class="manage-grid-mai-gr">
								<div class="manage-grid-mai-grL">
									备用网络：
								</div>
								<div class="manage-grid-mai-grR">
									<el-tag size="small"
										:type="descrData.T_online_s == 0 ? 'danger' : descrData.T_online_s == 1 ? '' : descrData.T_online_s == 2 ? 'danger' : 'danger'">
										{{ descrData.T_online_s == 0 ? '未启用' : descrData.T_online_s == 1 ? '在线' : descrData.T_online_s == 2 ? '离线' : '未知' }}
									</el-tag>
								</div>
							</div>
							<div class="manage-grid-mai-gr">
								<div class="manage-grid-mai-grL">
									监控状态：
								</div>
								<div class="manage-grid-mai-grR">
									<el-tag size="small"
										:type="descrData.T_monitor == 0 ? 'danger' : descrData.T_monitor == 1 ? '' : 'danger'">
										{{ descrData.T_monitor == 0 ? '未监控' : descrData.T_monitor == 1 ? '监控中' : '未知' }}
									</el-tag>
								</div>
							</div>
							<div class="manage-grid-mai-gr">
								<div class="manage-grid-mai-grL">
									定位：
								</div>
								<div class="manage-grid-mai-grR">
									<el-tag size="small" type="danger" v-show="descrData.T_Site == ''">未启用</el-tag>
									<div style="display: flex;align-items: center;" v-show="descrData.T_Site != ''">
										<div
											style="width: 30px;height: 30px;overflow: hidden;display: flex;justify-content: flex-start;">
											<img src="@/assets/img/bdMap.png"
												style="width: auto;height:100%;object-fit: contain;">
										</div>
										<div style="color: #0099cc;font-weight: bold;">BDS</div>
									</div>
								</div>
							</div>

							<div class="manage-grid-mai-gr" :title="'信号强度：' + descrData.T_rssi">
								<div class="manage-grid-mai-grL">
									信号强度：
								</div>
								<div class="manage-grid-mai-grR">
									{{ descrData.T_rssi }}
								</div>
							</div>
							<div class="manage-grid-mai-gr" :title="'电量：' + descrData.T_Dattery">
								<div class="manage-grid-mai-grL">
									电量：
								</div>
								<div class="manage-grid-mai-grR">
									{{ descrData.T_Dattery }}%
								</div>
							</div>
							<div class="manage-grid-mai-gr" :title="'软件版本：' + descrData.T_sver">
								<div class="manage-grid-mai-grL">
									软件版本：
								</div>
								<div class="manage-grid-mai-grR">
									{{ descrData.T_sver }}
								</div>
							</div>
							<div class="manage-grid-mai-gr" :title="'硬件版本：' + descrData.T_hver">
								<div class="manage-grid-mai-grL">
									硬件版本：
								</div>
								<div class="manage-grid-mai-grR">
									{{ descrData.T_hver }}
								</div>
							</div>

							<div class="manage-grid-mai-gr">
								<div class="manage-grid-mai-grL">
									温度：
								</div>
								<div class="manage-grid-mai-grR">
									<el-tag size="small" :type="descrData.T_ist == 2 ? 'danger' : ''">
										{{ descrData.T_ist == 2 ? '关闭' : '开启' }}
									</el-tag>
								</div>
							</div>
							<div class="manage-grid-mai-gr">
								<div class="manage-grid-mai-grL">
									湿度：
								</div>
								<div class="manage-grid-mai-grR">
									<el-tag size="small" :type="descrData.T_ish == 2 ? 'danger' : ''">
										{{ descrData.T_ish == 2 ? '关闭' : '开启' }}
									</el-tag>
								</div>
							</div>
							<div class="manage-grid-mai-gr" :title="'模组imei：' + descrData.T_imei">
								<div class="manage-grid-mai-grL">
									模组imei：
								</div>
								<div class="manage-grid-mai-grR">
									{{ descrData.T_imei }}
								</div>
							</div>
							<div class="manage-grid-mai-gr" :title="'sim卡号：' + descrData.T_iccid">
								<div class="manage-grid-mai-grL">
									sim卡号：
								</div>
								<div class="manage-grid-mai-grR">
									{{ descrData.T_iccid }}
								</div>
							</div>

							<div class="manage-grid-mai-gr" :title="'校准时间：' + descrData.T_CalibrationTime">
								<div class="manage-grid-mai-grL">
									校准时间：
								</div>
                                <div class="manage-grid-mai-grR">
									{{ descrData.T_CalibrationTime | setTime(descrData.T_CalibrationTime) }}
								</div>
							</div>
                            <div class="manage-grid-mai-gr" :title="'校准到期时间：' + descrData.T_CalibrationEndTime">
								<div class="manage-grid-mai-grL">
									校准到期时间：
								</div>
                                <div class="manage-grid-mai-grR"
									:title="setStyle(descrData.T_CalibrationEndTime) == 'red' ? '临近3个月到期' : ''"
									:style="{ color: setStyle(descrData.T_CalibrationEndTime) }">
									{{ descrData.T_CalibrationEndTime | setTime(descrData.T_CalibrationEndTime) }}
								</div>
							</div>
							<div class="manage-grid-mai-gr" :title="'验证时间：' + descrData.T_VerifyTime">
								<div class="manage-grid-mai-grL">
									验证时间：
								</div>
                                <div class="manage-grid-mai-grR">
									{{descrData.T_VerifyTime | setTime(descrData.T_VerifyTime)}}
								</div>
								
							</div>
							<div class="manage-grid-mai-gr" :title="'验证到期时间：：' + descrData.T_VerifyEndTime">
								<div class="manage-grid-mai-grL">
									验证到期时间：
								</div>
                                <div class="manage-grid-mai-grR"
									:title="setStyle(descrData.T_VerifyEndTime) == 'red' ? '临近3个月到期' : ''"
									:style="{ color: setStyle(descrData.T_VerifyEndTime) }">
									{{ descrData.T_VerifyEndTime | setTime(descrData.T_VerifyEndTime) }}
								</div>
							</div>
							<div class="manage-grid-mai-gr" :title="'巡检时间：' + descrData.T_PatrolTime">
								<div class="manage-grid-mai-grL">
									巡检时间：
								</div>
								<div class="manage-grid-mai-grR">
									{{ descrData.T_PatrolTime | setTime(descrData.T_PatrolTime) }}
								</div>
							</div>
							<div class="manage-grid-mai-gr" :title="'添加时间：' + descrData.CreateTime">
								<div class="manage-grid-mai-grL">
									添加时间：
								</div>
								<div class="manage-grid-mai-grR">
									{{ descrData.CreateTime | setTime(descrData.CreateTime) }}
								</div>
							</div>

						</div>
					</div>
					<el-row style="margin-top: 50px;">
						<el-button v-permission="'Device:Setup'" round @click="showHost">主机设置</el-button>
						<el-button v-permission="'Device:Log'" type="primary" @click="showLog" round>主机日志</el-button>
						<el-button v-permission="'Device:RestartShutdown'" type="success" @click="setoff()"
							round>重启/关机</el-button>
						<el-button v-permission="'Device:UpgradedVersion'" type="info" @click="showVersion"
							round>版本升级</el-button>
						<el-button v-permission="'Device:StopRecord'"
							:type="this.descrData.T_monitor == 1 ? 'warning' : 'primary'" @click="DevTask" round>
							{{ this.descrData.T_monitor == 1 ? '停止记录（已开启）' : '开始记录（已停止）' }}</el-button>
						<el-button type="primary" v-permission="'Device:SyncParameter'" @click="showArg" round>同步参数</el-button>

					</el-row>
					<el-table :data="tableData" border style="width: 100%;margin-top: 50px;">
						<template slot="empty">
							<noData></noData>
						</template>
						<el-table-column prop="T_name" label="传感器名称" width="180" :show-overflow-tooltip="true">
						</el-table-column>
						<el-table-column label="温度 ℃" v-if="T_ist == 1" width="180" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{ scope.row.T_DeviceSensorData.T_t }}
							</template>
						</el-table-column>
						<el-table-column label="温度阈值 ℃" v-if="T_ist == 1" width="180" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{ scope.row.T_DeviceSensorData.T_tl }} - {{ scope.row.T_DeviceSensorData.T_tu }}
							</template>
						</el-table-column>
						<el-table-column label="湿度 %" v-if="T_ish == 1" width="180" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{ scope.row.T_DeviceSensorData.T_rh }}
							</template>
						</el-table-column>
						<el-table-column label="湿度阈值 %" v-if="T_ish == 1" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{ scope.row.T_DeviceSensorData.T_rhl }} - {{ scope.row.T_DeviceSensorData.T_rhu }}
							</template>
						</el-table-column>
						<el-table-column prop="address" label="时间" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{ scope.row.T_DeviceSensorData.T_time }}
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button size="mini" v-permission="'DeviceSensor:Setup'"
									@click="handleEdit(scope.row)">设置</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<el-dialog title="新增" :visible.sync="dialogShowinstr1" width="40%" :append-to-body="true">
			<div class="">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="主机SN" prop="T_sn">
						<el-input v-model="ruleForm.T_sn"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')">新增</el-button>
						<el-button @click="dialogShowinstr1 = false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="说明" :visible.sync="dialogShowinstr" width="40%" :append-to-body="true">
			<!--  -->
			<div class="shuoM">
				<div class="shuoM1">设备管理里面 温湿度 不是实时数据</div>
				<div class="shuoM2">
					<h3 style="padding: 10px 0;">连接状态：</h3>
					<ul>
						<li>通讯正常</li>
						<li>设备已关机</li>
						<li>设备无网络（设备信号弱，或无信号）</li>
					</ul>
				</div>
				<div class="shuoM2">
					<h3 style="padding: 10px 0;">[4G]标签：</h3>
					<ul>
						<li><span style="color: #606266">[4G]</span> 不显示，代表没有4G备用网络</li>
						<li><span style="color: #00FF00">[4G]</span> 绿色，连接正常</li>
						<li><span style="color: #d2d2d2">[4G]</span> 灰色，连接断开</li>
						<li><span style="color: #ff7d7d">[4G]</span> 红色，连接异常</li>
					</ul>
				</div>
				<div class="shuoM2">
					<h3 style="padding: 10px 0;">监控状态：</h3>
					<ul>
						<li>监控状态</li>
						<li>未监控</li>
						<li>未知状态（由于设备异常断开网络，请以设备实际显示为准）</li>
					</ul>
				</div>
				<div class="shuoM2">
					<h3 style="padding: 10px 0;">弃用设备：</h3>
					<ul>
						<li>本设备所有探头 在数据展示中将会隐藏，可以通过指定SN搜索到历史数据</li>
					</ul>
				</div>
			</div>
		</el-dialog>

		<el-dialog title="版本升级" :visible.sync="dialogVisible3" :append-to-body="true">
			<el-table :data="versList" style="width: 100%">
				<template slot="empty">
					<noData></noData>
				</template>
				<el-table-column prop="T_version" width="100" label="版本" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="T_file" label="文件" :show-overflow-tooltip="true"></el-table-column>
				<el-table-column prop="CreateTime" label="时间" :show-overflow-tooltip="true"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="primary" v-permission="'Device:ProductUpgrade_list'" icon="el-icon-download"
							size="small" @click="setup(scope.row)">立即升级
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="margin-top: 20px;">
				<el-pagination @current-change="vCurrentChange" :current-page="versForm.page" :page-size="versForm.page_z"
					layout="total, prev, pager, next, jumper" :total="versTotal">
				</el-pagination>
			</div>
		</el-dialog>
		<el-dialog title="主机日志" :visible.sync="dialogVisible2" :append-to-body="true">
			<el-table :data="LogList" style="width: 100%">
				<template slot="empty">
					<noData></noData>
				</template>
				<el-table-column prop="Logs_Txt" label="内容" :show-overflow-tooltip="true"></el-table-column>
				<el-table-column prop="CreateTime" label="时间" :show-overflow-tooltip="true"></el-table-column>
			</el-table>
			<div style="margin-top: 20px;">
				<el-pagination layout="prev, pager, next" :total="logTotal" @size-change="handleSizeChange"
					@current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</el-dialog>
		<el-dialog title="主机设置" :visible.sync="dialogVisible" :append-to-body="true" width="880px">
			<el-tabs v-model="activeName" ref="tabs">
				<el-tab-pane label="主机参数" name="first">
					<div style="height: 400px;overflow-y: auto;">
						<el-form :inline="true" :model="Hostline" label-width="180px" class="demo-form-inline">
							<el-form-item label="Sn">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_sn" disabled placeholder="请输入Sn"></el-input>
									<i class="el-icon-question" slot="reference" style="margin-left:10px;opacity: 0;"></i>
								</div>
							</el-form-item>
							<el-form-item label="设备名称">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_name" placeholder="请输入设备名称"></el-input>
									<el-popover placement="top-start" title="提示" width="200" trigger="hover"
										content="设备名称在0-20个字节">
										<i class="el-icon-question" slot="reference"
											style="margin-left:10px;cursor: pointer;"></i>
									</el-popover>
								</div>
							</el-form-item>
							<el-divider></el-divider>
							<el-form-item label="数据保存间隔">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_saveT" placeholder="请输入数据保存间隔"></el-input>
									<el-popover placement="top-start" title="提示" width="200" trigger="hover"
										content="单位(秒),60~3600,默认: 60 ">
										<i class="el-icon-question" slot="reference"
											style="margin-left:10px;cursor: pointer;"></i>
									</el-popover>
								</div>
							</el-form-item>
							<el-form-item label="轮播间隔"><!-- 采集器采样率 -->
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_scan" placeholder="请输入轮播间隔"></el-input>
									<el-popover placement="top-start" title="提示" width="200" trigger="hover"
										content="单位(秒),1~240">
										<i class="el-icon-question" slot="reference"
											style="margin-left:10px;cursor: pointer;"></i>
									</el-popover>
								</div>
							</el-form-item>
							<el-form-item label="传感器数量">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_snum" placeholder="请输入传感器数量"></el-input>
									<el-popover placement="top-start" title="提示" width="200" trigger="hover"
										content="传感器数量">
										<i class="el-icon-question" slot="reference"
											style="margin-left:10px;cursor: pointer;"></i>
									</el-popover>
								</div>
							</el-form-item>
							<el-form-item label="节能模式">
								<div style="display: flex;align-items: center;">
									<el-switch v-model="Hostline.T_dormancy" :inactive-value="0"
										:active-value="1"></el-switch>
									<el-popover placement="top-start" title="提示" width="200" trigger="hover"
										content="开启后，实时性会下降，待机时间会增加，仅限于长途运输是开启">
										<i class="el-icon-question" slot="reference"
											style="margin-left:10px;cursor: pointer;"></i>
									</el-popover>
								</div>
							</el-form-item>
							<el-divider></el-divider>

							<el-form-item label="超限报警触发间隔">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_overA" placeholder="请输入超限报警触发间隔"></el-input>
									<el-popover placement="top-start" title="提示" width="200" trigger="hover"
										content="单位(秒),60~600,默认: 60 ">
										<i class="el-icon-question" slot="reference"
											style="margin-left:10px;cursor: pointer;"></i>
									</el-popover>
								</div>
							</el-form-item>
							<el-form-item label="传感器掉线报警触发间隔">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_lostA" placeholder="请输入传感器掉线报警触发间隔"></el-input>
									<el-popover placement="top-start" title="提示" width="200" trigger="hover"
										content="单位(秒),0,60~600,为0时只触发一次 默认: 60">
										<i class="el-icon-question" slot="reference"
											style="margin-left:10px;cursor: pointer;"></i>
									</el-popover>
								</div>
							</el-form-item>
							<el-form-item label="断电报警触发间隔">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_outA" placeholder="请输入断电报警触发间隔"></el-input>
									<el-popover placement="top-start" title="提示" width="200" trigger="hover"
										content="单位(秒),0,60~600,为0时只触发一次 默认: 60">
										<i class="el-icon-question" slot="reference"
											style="margin-left:10px;cursor: pointer;"></i>
									</el-popover>
								</div>
							</el-form-item>
							<el-form-item label="电池电量下限">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_bat" placeholder="请输入电池电量下限"></el-input>
									<el-popover placement="top-start" title="提示" width="200" trigger="hover"
										content="单位(%),0~30,默认20">
										<i class="el-icon-question" slot="reference"
											style="margin-left:10px;cursor: pointer;"></i>
									</el-popover>
								</div>
							</el-form-item>
							<el-divider></el-divider>
							<el-form-item label="超限预警触发间隔">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_warn" placeholder="请输入超限预警触发间隔"></el-input>
									<el-popover placement="top-start" title="提示" width="200" trigger="hover"
										content="单位(秒),0,60~600,为0时只触发一次 默认: 60">
										<i class="el-icon-question" slot="reference"
											style="margin-left:10px;cursor: pointer;"></i>
									</el-popover>
								</div>
							</el-form-item>
							<el-form-item label="超限预警延时">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_warnD" placeholder="请输入超限预警延时"></el-input>
									<el-popover placement="top-start" title="提示" width="200" trigger="hover"
										content="单位(秒),0~600,默认0">
										<i class="el-icon-question" slot="reference"
											style="margin-left:10px;cursor: pointer;"></i>
									</el-popover>
								</div>
							</el-form-item>
							<br />
							<el-divider></el-divider>
							<el-form-item label="打印机名称">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_btname" placeholder="请输入打印机名称"></el-input>
									<i class="el-icon-question" slot="reference" style="margin-left:10px;opacity: 0;"></i>
								</div>
							</el-form-item>
							<el-form-item label="打印机服务号">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_btsid" placeholder="请输入打印机服务号"></el-input>
									<i class="el-icon-question" slot="reference" style="margin-left:10px;opacity: 0;"></i>
								</div>
							</el-form-item>
							<el-form-item label="蓝牙特征码">
								<div style="display: flex;align-items: center;">
									<el-input v-model="Hostline.T_btchar" placeholder="请输入蓝牙特征码"></el-input>
									<i class="el-icon-question" slot="reference" style="margin-left:10px;opacity: 0;"></i>
								</div>
							</el-form-item>
						</el-form>
					</div>
					<div class="diapir-nav1-2">
						<div style="display: flex;justify-content: space-between;width: 100%;">
							<div>
								<el-button type="primary" v-permission="'Device:ParameterList'"
									@click="onSubpast">主机参数记录</el-button>
							</div>
							<div v-permission="'Device:ParameterPu'">
								<el-button @click="dialogVisible = false">取消</el-button>
								<el-button type="primary" @click="onSubmit1(true)">立即配置</el-button>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="主机配置" name="second">
					<el-form ref="Hostform" :model="Hostform" label-width="130px" :inline="true">
						<el-form-item label="验证时间">
							<el-date-picker v-model="Hostform.T_VerifyTime" type="date" value-format="yyyy-MM-dd"
                            @change="handleClear($event,'T_VerifyEndTime')" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="校准时间">
							<el-date-picker v-model="Hostform.T_CalibrationTime" type="date" value-format="yyyy-MM-dd"
                            @change="handleClear($event,'T_CalibrationEndTime')" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
                        <el-form-item label="验证到期时间">
							<el-date-picker
                            :disabled="Hostform.T_VerifyTime?false:true"
                            v-model="Hostform.T_VerifyEndTime" type="date" value-format="yyyy-MM-dd"
								placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="校准到期时间">
							<el-date-picker
                            :disabled="Hostform.T_CalibrationTime?false:true"
                            v-model="Hostform.T_CalibrationEndTime" type="date" value-format="yyyy-MM-dd"
								placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="巡检时间">
							<el-date-picker v-model="Hostform.T_PatrolTime" type="date" value-format="yyyy-MM-dd"
								placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						<br />
						<el-form-item label="温度显示">
							<el-switch v-model="Hostform.T_ist" :inactive-value="2" :active-value="1"></el-switch>
						</el-form-item>
						<el-form-item label="湿度显示">
							<el-switch v-model="Hostform.T_ish" :inactive-value="2" :active-value="1"></el-switch>
						</el-form-item>
						<br />
						<el-form-item label="是否弃用">
							<el-radio-group v-model="Hostform.T_State">
								<el-radio :label="0">弃用设备</el-radio>
								<el-radio :label="1">开启设备</el-radio>
							</el-radio-group>
						</el-form-item>
                         </br>
                        <el-form-item label="历史记录展示方式" v-permission="'Device:ParameterList'">
							<el-switch v-model="Hostline.T_alarm" @change="onSubmit1(false)" :inactive-value="0" :active-value="1"></el-switch>
						</el-form-item>
					</el-form>
					<div class="diapir-nav1-2" v-permission="'Device:Pu'">
						<el-button @click="dialogVisible = false">取消</el-button>
						<el-button type="primary" @click="subHost">立即配置</el-button>
					</div>
				</el-tab-pane>
                
			</el-tabs>
			<el-dialog width="700px" title="设备参数记录" :visible.sync="dialogVisibleinner" append-to-body>
				<div style="display: flex;align-items: center;padding-bottom: 10px;">
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<span
							style="display: block;width: 15px;height: 15px;background: #909399;border-radius: 50%;"></span>
						<p style="font-size: 12px;margin-left: 5px;">等待执行</p>
					</div>
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<span
							style="display: block;width: 15px;height: 15px;background: #67c23a;border-radius: 50%;"></span>
						<p style="font-size: 12px;margin-left: 5px;">修改成功</p>
					</div>
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<span
							style="display: block;width: 15px;height: 15px;background: #f56c6c;border-radius: 50%;"></span>
						<p style="font-size: 12px;margin-left: 5px;">修改失败</p>
					</div>
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<span
							style="display: block;width: 15px;height: 15px;background: #e6a23c;border-radius: 50%;"></span>
						<p style="font-size: 12px;margin-left: 5px;">覆盖参数</p>
					</div>
				</div>
				<div style="height: 500px;overflow-y: auto;padding-right: 20px;">
					<div v-if="pastList == null">
						<noData></noData>
					</div>
					<div v-else>
						<el-alert :title="item.T_text" style="margin-top: 10px ;" :closable="false"
							:type="item.T_SendState == 0 ? 'info' : item.T_SendState == 1 ? 'success' : item.T_SendState == 2 ? 'error' : 'warning'"
							show-icon v-for="item, index in pastList" :key="index">
						</el-alert>
					</div>
				</div>
			</el-dialog>
		</el-dialog>

		<el-dialog title="设备设置" :visible.sync="dialogVisible4" :append-to-body="true">
			<el-tabs v-model="activeName1" ref="tabs1" @tab-click="tabClick">
				<el-tab-pane label="传感器参数" name="firsts">
					<el-form ref="form1" :model="diaForm1" label-width="100px" style="height: 500px;overflow-y: auto;">
						<el-form-item label="设备名称">
							<div style="display: flex;align-items: center;">
								<div style="display:flex;align-items: center;">
									<el-input v-model="diaForm1.T_devName" disabled placeholder="请输入设备名称"
										style="width: 70%;"></el-input>
									<el-input v-model="diaForm1.T_sn" disabled
										style="width: 50%;margin-left:10px;"></el-input>
								</div>
								<i class="el-icon-question" slot="reference" style="margin-left:10px;opacity: 0;"></i>
							</div>
						</el-form-item>
						<el-form-item label="传感器名称">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm1.T_name" placeholder="请输入传感器名称" style="width: 70%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="设备名称在0-20个字节">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<!-- <el-form-item label="传感器采样率">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm1.T_speed" placeholder="请输入传感器采样率" style="width: 70%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="秒(1~240),默认: 15 ">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item> -->
						<br />
						<el-form-item label="温度范围">
							<div class="astricts" style="display: flex;align-items: center;">
								<el-input v-model="diaForm1.T_Tlower" placeholder="请输入温度范围下限"
                                    @change="inputChange(diaForm1.T_Tlower,diaForm1.T_Tupper,0)"
									style="width: 20%;"></el-input>
								<span style="margin: 0 10px;">-</span>
								<el-input v-model="diaForm1.T_Tupper" placeholder="请输入温度范围上限"
                                 @change="inputChange(diaForm1.T_Tlower,diaForm1.T_Tupper,0)"
									style="width: 20%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="-40.0 ~ 80.0">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="湿度范围">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm1.T_RHlower" placeholder="请输入湿度范围下限"
                                @change="inputChange(diaForm1.T_RHlower,diaForm1.T_RHupper,1)"
									style="width: 20%;"></el-input>
								<span style="margin: 0 10px;">-</span>
								<el-input v-model="diaForm1.T_RHupper" placeholder="请输入湿度范围上限"
                                @change="inputChange(diaForm1.T_RHlower,diaForm1.T_RHupper,1)"
									style="width: 20%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="-40.0 ~ 80.0">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>

						<el-form-item label="预警">
							<div style="display: flex;align-items: center;">
								<el-switch v-model="diaForm1.T_enprel" :inactive-value="0" :active-value="1"></el-switch>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="推送预警消息，设为 空库后 不推送预警消息">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="温度范围">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm1.T_tprel" placeholder="请输入温度范围下限"
                                @change="inputChange(diaForm1.T_tprel,diaForm1.T_tpreu,2)"
                                style="width: 20%;"></el-input>
								<span style="margin: 0 10px;">-</span>
								<el-input v-model="diaForm1.T_tpreu" placeholder="请输入温度范围上限"
                                @change="inputChange(diaForm1.T_tprel,diaForm1.T_tpreu,2)"
                                style="width: 20%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="0.0 ~ 100.0">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="湿度范围">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm1.T_hprel" placeholder="请输入湿度范围下限"
                                @change="inputChange(diaForm1.T_hprel,diaForm1.T_hpreu,3)"
                                style="width: 20%;"></el-input>
								<span style="margin: 0 10px;">-</span>
								<el-input v-model="diaForm1.T_hpreu" placeholder="请输入湿度范围上限"
                                @change="inputChange(diaForm1.T_hprel,diaForm1.T_hpreu,3)"
                                style="width: 20%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="0.0 ~ 100.0">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="预警记录数据">
							<div style="display: flex;align-items: center;">
								<el-switch v-model="diaForm1.T_enprelnote" :inactive-value="0"
									:active-value="1"></el-switch>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="关闭后，只推送报警记录，不记录数据">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="启用">
							<div style="display: flex;align-items: center;">
								<el-switch v-model="diaForm1.T_en" :inactive-value="0" :active-value="1"></el-switch>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="功能（实时、记录、报警、预警）">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="空库">
							<div style="display: flex;align-items: center;">
								<el-switch v-model="diaForm1.T_free" :inactive-value="0" :active-value="1"></el-switch>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="1.启用（正常 实时、记录, 不 报警、预警）2.关闭（正常 实时、记录、报警、预警）">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
					</el-form>
					<div class="diapir-nav1-2">
						<div style="display: flex;justify-content: space-between;width: 100%;">
							<div>
								<el-button type="primary" v-permission="'DeviceSensor:ParameterList'"
									@click="onSubpast1">设备参数记录</el-button>
							</div> 
							<div v-permission="'DeviceSensor:ParameterPu'">
								<el-button @click="dialogVisible4 = false">取消</el-button>
								<el-button type="primary" @click="Submit1">立即提交</el-button>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="传感器配置" name="seconds">
					<el-form ref="form2" :model="diaForm2" label-width="100px">
						<el-form-item label="实时数据排序">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm2.T_sort" style="width: 70%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="越小越靠前,可以为负数">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="3D视图订阅">
							<el-input v-model="diaForm2.T_3dview" style="width: 70%;"></el-input>
						</el-form-item>
						<el-form-item label="类型选择">
							<el-radio-group v-model="diaForm2.T_type">
								<el-radio :label="0" style="line-height: 30px;">默认</el-radio>
								<el-radio :label="item.Id" v-for="(item, index) in RadioData" :key="index"
									style="line-height: 30px;">{{ item.T_name }}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="屏蔽数据展示">
							<el-radio-group v-model="diaForm2.T_datashow">
								<el-radio :label="0">屏蔽展示</el-radio>
								<el-radio :label="1">正常展示</el-radio>
							</el-radio-group>
						</el-form-item>
                        <!-- <el-form-item label="金卫信对接" v-permission="'JWX:docking'">
                            <el-radio-group v-model="diaForm2.probeIdNo">
								<el-radio :label="radios">显示</el-radio>
								<el-radio label="">不显示</el-radio>
							</el-radio-group>
						</el-form-item> -->
					</el-form>
					<div class="diapir-nav1-2" v-permission="'DeviceSensor:Pu'">
						<el-button @click="dialogVisible4 = false">取消</el-button>
						<el-button type="primary" @click="Submit2">立即提交</el-button>
					</div>
				</el-tab-pane>
                <el-tab-pane label="金卫信对接" name="joint" v-if="_permission" v-permission="'JWX:docking'">
                    <el-form ref="jointFormRef" :rules="joinRules" :model="jointForm" label-width="130px" :inline="true">
                        <el-form-item label="中继器网关序号" style="pointer-events:none">
                            <el-input :value="jointForm.sensorIdNo" class="_jointStyle"  placeholder="中继器网关序号"></el-input>
                        </el-form-item>
                        <el-form-item label="中继器网关名称" style="pointer-events:none">
                            <el-input :value="jointForm.sensorName" class="_jointStyle"  placeholder="中继器网关名称"></el-input>
                        </el-form-item>
                        <el-form-item label="传感器探头序号" style="pointer-events:none">
                            <el-input :value="jointForm.probeIdNo" class="_jointStyle"  placeholder="中继器网关名称"></el-input>
                        </el-form-item>
                        <el-form-item label="单位名称" prop="depaCode">
                            <el-select v-model="jointForm.depaCode" class="_jointStyle" @change="jointForm.idNo = ''" filterable placeholder="请选择">
                                <el-option v-for="item,index in options" :key="index" :label="item.depaName" :value="item.depaCode">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="绑定设备" prop="idNo">
                            <el-select v-model="jointForm.idNo" :disabled="jointForm.depaCode?false:true" class="_jointStyle" filterable placeholder="请先选择单位名称">
                                <el-option v-for="item,index in subOptions" :key="index" :label="item.name" :value="item.idNo">
                                    <div style="display: flex; align-items: center;justify-content: space-between;"> <!-- 使用 flex 布局 -->
                                        <div class="a" style="flex: 1;">{{ item.name }}</div>
                                        <div class="b" style="color: #8492a6; font-size: 13px;flex-shrink: 0;min-width: 160px;text-align: right;">{{item.idNo}}</div>
                                    </div>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <div class="diapir-nav1-2">
						<el-button @click="dialogVisible4 = false">取消</el-button>
						<el-button type="primary" @click="_subJoin">立即配置</el-button>
					</div>
                </el-tab-pane>
			</el-tabs>
			<el-dialog width="700px" title="设备参数记录" :visible.sync="dialogVisible4inner" append-to-body>
				<div style="display: flex;align-items: center;padding-bottom: 10px;">
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<span
							style="display: block;width: 15px;height: 15px;background: #909399;border-radius: 50%;"></span>
						<p style="font-size: 12px;margin-left: 5px;">等待执行</p>
					</div>
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<span
							style="display: block;width: 15px;height: 15px;background: #67c23a;border-radius: 50%;"></span>
						<p style="font-size: 12px;margin-left: 5px;">修改成功</p>
					</div>
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<span
							style="display: block;width: 15px;height: 15px;background: #f56c6c;border-radius: 50%;"></span>
						<p style="font-size: 12px;margin-left: 5px;">修改失败</p>
					</div>
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<span
							style="display: block;width: 15px;height: 15px;background: #e6a23c;border-radius: 50%;"></span>
						<p style="font-size: 12px;margin-left: 5px;">覆盖参数</p>
					</div>
				</div>
                <div style="height: 500px;overflow-y: auto;padding-right: 20px;">
					<div v-if="sensorList == null">
						<noData></noData>
					</div>
					<el-alert :title="item.T_text" style="margin-top: 10px ;" :closable="false"
						:type="item.T_SendState == 0 ? 'info' : item.T_SendState == 1 ? 'success' : item.T_SendState == 2 ? 'error' : 'warning'"
						show-icon v-for="item, index in sensorList" :key="index">
					</el-alert>
				</div>
			</el-dialog>
		</el-dialog>
	</div>
</template>
<script>
import { pertabs } from "@/permission.js"
import {
	DevList,
	DevSenList,
	DevAdd,
	DevParameter_List,
	DevParameter_Pu,
	DevEdit,
	DevTask,
	DevSenParPu,
	DevParameter_Get,
	DevRes,
	DevLog,
	ProUpList,
	DevUpgrade,
	DevParGet,
	DevParParPu,
	DevSenEdit,
	DevSenParList,
	DevGet,
	DevListAll,
	DevParameSync,
    getDepartment,
    getColdEquipment,
    sensorUpload,
    probeUpload,probeRefUpload,
    createMaintainInfo,findMaintainInfo
} from "../../api/equipmentManagement/management.js"

import store from "../../store"
export default {
    computed: {
        _permission() {
            return this.$store.state.permission.includes('JWX:docking')
        }
    },
	data() {
		return {
            radios:'',
            joinRules:{
                depaCode: [{ required: true, message: '单位名称', trigger: 'change' }],
                idNo: [{ required: true, message: '绑定设备', trigger: 'change' }],
            },
            multipleTable:[],//传感器探头列表
            jointForm:{ 
                T_id:null,
                T_sn:'',
                sensorIdNo:'', //中继器网关序号 (input) sn_年月
                // depaName:'', //单位名称（select）
                depaCode:'', //单位编码 *
                idNo:'', //设备唯一编号 *
                sensorName:'', //传感器名称 *
                deviceName:'', //传感器探头名称 *
                probeIdNo:'', //传感器探头序号 *
            },
            options: [],
            subOptions:[],
			RadioData: [],
			T_ist: 1,
			T_ish: 1,
			times: null,
			versForm: {
				T_model: '',
				page: 1,
				page_z: 5
			},
			versList: [],
			versTotal: 0,
			LogList: [],
			logTotal: 0,
			Page_size: 0,
			Hostform: { //主机配置提交参数
				T_sn: '',
				T_State: '',
				T_ist: '',
				T_ish: '',
				T_VerifyTime: '',
				T_CalibrationTime: '',
                T_VerifyEndTime:'',//验证到期时间
				T_CalibrationEndTime:'',//校准到期时间
				T_PatrolTime: '',

                
			},
			LogForm: {
				T_sn: '',
				page: 1,
				page_z: 10
			},
			Hostline: {}, //主机参数提交
			pastList: [],
			sensorList: [],

			ShowTishi: '',
			diapirForm: {},
			diapirRec: [],
			pages: {
				T_name: '',
				page: 1,
				page_z: 30
			},
			pages1: {
				T_sn: '',
				T_l_p: '',
				T_give: ''
			},
			pages2: {
				T_id: '',
				T_sn: '', //设备SN
			},
			diaForm1: {},
			diaForm2: {
				T_id: null,
				T_sn: null,
				T_sort: null,
				T_type: 0,
				T_datashow: null,
				T_3dview: null,
			},
			loadTable: false,
			NavList: [],
			descrData: {},

			descrDataPir: [],
			radio: '1',
			tableData: [], //传感器列表
			dialogVisibleinner: false,
			dialogShowinstr: false, //说明弹窗
			dialogShowinstr1: false, //新增弹窗
			dialogVisible: false, //设备管理弹窗
			dialogVisible2: false, //系统日志弹窗
			dialogVisible3: false,
			dialogVisible4: false,
			dialogVisible4inner: false,
			activeName1: 'firsts', //传感器管理默认显示
			activeName: 'first', //系统日志默认显示
			navNum: 0,
			ruleForm: { //新增
				T_sn: '',
			},
			rules: {
				T_sn: [{
					required: true,
					message: '请输入主机SN',
					trigger: 'blur'
				},],
			},
			form: {
				name: '',
				region: '',
				date1: '',
				date2: '',
				delivery: false,
				type: [],
				resource: '',
				desc: ''
			},
            downArr:[false,false,false,false],
            debounceTimer:null
		}
	},
	filters: {
		setTime(time) {
			if (time != undefined) return time.split(' ')[0]
		},

	},
	beforeDestroy() {
		clearInterval(this.times)
	},
	mounted() {
		this.GetDevListApi()
		this.times = setInterval(() => {
			if (this.NavList.length != 0) {
				this.getDevGetApi(this.descrData)
				this.GetDevSenListApi(this.descrData)
			}
		}, 3000)
	},
    watch: {
        'jointForm.depaCode': {
            handler(value) {
                if (value) {
                    this.loadChildren(value);
                }
            },
            immediate: true
        }
    },
	methods: {
        // 点击标签时触发(等于金卫信对接项&&下拉框为空时拉取单位列表)
        tabClick(e){
            if(e.name=='joint'&&this.options.length==0){
            	this.handleClick()
            }
        },
        //拉取单位列表，当选择值时，触发监听拉取绑定设备列表
        async handleClick() {
            const {data:result} = await getDepartment({})
            if(result.Code==200){
                this.options = result.Data || []
            }
		},
        // 动态添加子选项
        async loadChildren(code) {
            const {data:result} = await getColdEquipment({DepaCode:code})
            this.subOptions = result.Data || []   
        },
        
        _subJoin(){
            this.$refs['jointFormRef'].validate(async(valid) => {
                if (valid) {
                    console.log('通过验证',this.jointForm)
                    let {depaCode,sensorIdNo,sensorName,probeIdNo,deviceName,idNo} = this.jointForm

                    const requests = [
                        { func: sensorUpload, params: { depaCode, sensorIdNo, sensorName } },
                        { func: probeUpload, params: { depaCode, probeIdNo, deviceName, model: 1, type: 1 } },
                        { func: probeRefUpload, params: { depaCode, idNo, sensorIdNo, probeIdNo } },
                        { func: createMaintainInfo, params: this.jointForm }
                    ];
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    try {
                        for (const { func, params } of requests) {
                            const { data } = await func(params);
                            if (data.Code!== 200) {
                                loading.close();
                                return;
                            }
                        }
                        loading.close();
                        this.$message.success('所有请求发送成功');
                    } catch (error) {
                        loading.close();
                        this.$message.error('操作失败');
                    }

                    // // 中继器基础信息上传
                    // const {data:result1} = await sensorUpload({depaCode,sensorIdNo,sensorName})
                    // console.log('通过验证1',result1)
                    // //传感器探头信息上传
                    // const {data:result2} = await probeUpload({ 
                    //     depaCode,
                    //     probeIdNo,
                    //     deviceName,
                    //     model:1,
                    //     type:1
                    // })
                    // console.log('通过验证2',result1)
                    // //同步平台
                    // const {data:result3} = await probeRefUpload({depaCode,idNo,sensorIdNo,probeIdNo})
                    // console.log('通过验证3',result3)
                    // const {data:result4} = await createMaintainInfo(this.jointForm)
                    // console.log('通过验证4',result4)
                    
                    

                    this.$message.success('设置成功')
                    this.dialogVisible = false
                    this.pages.page = 1
                    this.NavList = []
                    this.GetDevListApi()
                    // let obj = {...this.jointForm}
                    // const res = await DevEdit(obj)
                    // if (res.data.Code === 200 && res.data.Msg === "ok!") {
                    //     let {depaCode,sensorIdNo,sensorName} = obj
                    //     const {data:result} = await sensorUpload({depaCode,sensorIdNo,sensorName})
                    //     if(result.Code==200){
                    //         this.$message.success('设置成功')
                    //         this.dialogVisible = false
                    //         this.pages.page = 1
                    //         this.NavList = []
                    //         this.GetDevListApi()
                    //     }
                        
                    // }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
           
        },
        
        handleClear(e,key){if(!e)this.Hostform[key]=''},
        inputChange(valuedown,valuetop,j){
            if(Number(valuedown)>Number(valuetop)){
                this.$alert('下限值不能大于上限值，不修改无法提交', '提示', {
                    confirmButtonText: '我知道了',callback: action => {}
                });
            }
            this.downArr[j]= Number(valuedown)>Number(valuetop)
        },
		showArg() {
			this.$confirm('是否同步参数？', '同步参数', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				DevParameSync({T_sn:this.descrData.T_sn}).then(res=>{
					if (res.data.Code==200) {
						this.$message.success('ok，同步成功');
					}
				})
				
			}).catch(() => {
				this.$message.info('已取消同步参数');
			});
			
		},
		setStyle(time) {
			const isTime = new Date().getTime()
			const toTime = new Date(time).getTime()
			const numTime = isTime - toTime
			if (toTime - isTime <= 90 * 86399000) {//小于3个月提示临近3月
				return 'red'
			} else {
				return '#000'
			}
		},
		getDevListAllApi() {
			DevListAll({}).then(res => {
				if (res.data.Code == 200) {
					this.RadioData = res.data.Data.Data
					////console.log('----',res)
				}
			})
		},
		setup(item) {
			////console.log(item, this.descrData)
			this.$confirm('此操作将升级更新版本, 是否立即升级更新?', '更新', {
				confirmButtonText: '立即更新',
				cancelButtonText: '放弃更新',
				type: 'warning',
				center: true
			}).then(() => {
				DevUpgrade({
					T_sn: this.descrData.T_sn,
					T_version: item.T_version,
					T_file: item.T_file
				}).then(res => {
					if (res.data.Code === 200) {
						this.$message.success('操作完成，更新成功')
					}
				})
			}).catch(() => { });
		},
		showVersion() {
			this.versForm.page = 1
			////console.log(this.versForm)
			this.dialogVisible3 = true

			this.versForm.T_model = this.descrData.T_model
			this.getProUpApi()
		},
		getProUpApi() {
			ProUpList(this.versForm).then(res => {
				if (res.data.Code == 200) {
					this.versList = res.data.Data.Data
					this.versTotal = res.data.Data.Num
				}
			})
		},


		vCurrentChange(val) {
			////console.log(`当前页: ${val}`);
			this.versForm.page = val
			this.getProUpApi()
		},


		handleSizeChange(val) {
			////console.log(`每页 ${val} 条`);
			this.LogForm.page_z = val
			this.getDevLogApi()
		},
		handleCurrentChange(val) {
			////console.log(`当前页: ${val}`);
			this.LogForm.page = val
			this.getDevLogApi()
		},
		showLog() {
			////console.log('主机日志',this.LogForm)
			this.LogForm.page = 1
			this.logTotal = 1
			this.dialogVisible2 = true
			this.LogForm.T_sn = this.descrData.T_sn
			this.getDevLogApi()
		},
		getDevLogApi() {
			DevLog(this.LogForm).then(res => {
				if (res.data.Code == 200) {
					this.LogList = res.data.Data.DeviceLogs
					this.logTotal = res.data.Data.Num
				}
			})
		},
		DevTask() { //远程启停
			var that = this
			////console.log('远程启停', this.descrData)
			if (this.descrData.T_monitor === 2) {
				this.$message.error('网络不佳')
				return
			}
			if (this.descrData.T_online == 1 || this.descrData.T_online_s == 1) {
				this.$confirm(this.descrData.T_monitor == 1 ? '停止记录操作，是否继续？' : '开始记录操作，是否继续？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					DevTask({
						T_sn: this.descrData.T_sn,
						T_task: this.descrData.T_monitor === 1 ? 'stop' : 'start'
					}).then(res => {
						if (res.data.Code === 200 && res.data.Msg === "ok!") {
							that.$message.success('远程启停，设置成功')
							this.pages.page = 1
							this.NavList = []
							this.GetDevListApi()
						}
					})
				}).catch(() => { });
			} else {
				this.$message.error('设备离线')
			}
		},
		onSubpast() { //历史数据
			DevParameter_List({
				T_sn: this.Hostform.T_sn
			}).then(res => {
				////console.log('历史数据', this.Hostform.T_sn, res)
				if (res.data.Code === 200) {
					this.pastList = res.data.Data
					this.dialogVisibleinner = true
				}
			})
		},
		onSubpast1() { //
			////console.log('****',this.pages2)
			DevSenParList(this.pages2).then(res => {
				////console.log('历史数据15151',res)
				if (res.data.Code === 200) {
					this.dialogVisible4inner = true
					this.sensorList = res.data.Data
				}
			})
		},
		onSubmit1(flag) {
            console.log('提交参数',this.Hostline)
			DevParameter_Pu(this.Hostline).then(res => {
				if (res.data.Code === 200 && res.data.Msg === "ok!") {
					this.$message.success('设置成功')
                    if(flag)this.dialogVisible = false
					this.pages.page = 1
					this.NavList = []
					this.GetDevListApi()
				}
			})
		},
		GetDevSenParameter_ListApi() {
			DevParameter_Get({
				T_sn: this.descrData.T_sn
			}).then(res => {
				console.log('获取数据========8888', res)
				if (res.data.Code === 200 && res.data.Msg === "ok!") {
					this.Hostline = res.data.Data[0]
					// this.diapirForm = res.data.Data[0]
					// this.diapirRec = res.data.Data
					// var arr = eval('(' + res.data.Data[0].T_chDecTotal + ')')
					// this.diapirForm.T_chDecTotal = Object.values(arr)
					// this.diapirForm.T_chDecTotal = JSON.parse(res.data.Data[0].T_chDecTotal)
				} else {
					this.ShowTishi = res.data.Msg
				}
			})
		},
		GetDevEditApi() { //主机设置>主机配置
			var obj = { ...this.Hostform }
			obj.T_CalibrationTime = obj.T_CalibrationTime + ' 00:00:00'
			obj.T_PatrolTime = obj.T_PatrolTime + ' 00:00:00'
			obj.T_VerifyTime = obj.T_VerifyTime + ' 00:00:00'
            obj.T_VerifyEndTime = obj.T_VerifyEndTime + ' 00:00:00'
			obj.T_CalibrationEndTime = obj.T_CalibrationEndTime + ' 00:00:00'
			DevEdit(obj).then(res => {
				if (res.data.Code === 200 && res.data.Msg === "ok!") {
					this.$message.success('设置成功')
					this.dialogVisible = false
					this.pages.page = 1
					this.NavList = []
					this.GetDevListApi()
				}
			})
		},
        
		showHost() { //点击主机设置
			var perData1 = pertabs('Device:Parameter')
			var perData2 = pertabs('Device:Config')
			if (perData1 != false || perData2 != false) {
                this.activeName = 'first'
				this.dialogVisible = true
                this.options = []
			} else {
				this.$message.error('无权查看')
				return
			}
			this.$nextTick(() => {
				if (perData1 == false) {//等于true，有权限
					this.$refs.tabs.$children[0].$refs.tabs[0].style.display = 'none'
					this.activeName = 'second'
				}
				if (perData2 == false) {//等于true，有权限
					this.$refs.tabs.$children[0].$refs.tabs[1].style.display = 'none'
					this.activeName = 'first'
				}
			})

            for (let key in this.Hostform) {
                if (this.descrData.hasOwnProperty(key)) {
                    this.Hostform[key] = this.descrData[key];
                }
            }
            // for (let key in this.jointForm) {
            //     if (this.descrData.hasOwnProperty(key)) {
            //         this.jointForm[key] = this.descrData[key];
            //     }
            // }
			this.GetDevSenParameter_ListApi()
		},
		subHost() { //提交主机配置
			this.GetDevEditApi()
		},

		scroll(e) {
			let isBottom = e.target.scrollTop + e.target.clientHeight == e.target.scrollHeight
			if (isBottom && this.pages.page < this.Page_size) { //true已经触底
				let height = e.target.scrollTop
				this.pages.page = this.pages.page + 1
				this.GetDevListApi()
				e.target.scrollTop = height
			}
			
			
		},
		GetDevListApi() { //列表
			DevList(this.pages).then(async (res) => {
				if (res.data.Code === 200 && res.data.Msg === "ok!") {
					if (res.data.Data.Device_lite != null) {
						this.Page_size = res.data.Data.Page_size
						this.NavList = [...this.NavList, ...res.data.Data.Device_lite]
						this.GetDevSenListApi(res.data.Data.Device_lite[this.navNum])
						this.getDevGetApi(res.data.Data.Device_lite[this.navNum])
					} else {
						this.NavList = []
					}
				}
			})
		},
		GetDevSenListApi(obj) { //传感器列表
			////console.log('=========>添加参数在进行', obj)
			this.loadTable = true
			DevSenList({
				T_sn: obj.T_sn,
				page: 1,
				page_z: 999999
			}).then(res => {
				////console.log('传感器列表------------------->', res)
				if (res.data.Code === 200 && res.data.Msg === "ok!") {
					this.tableData = res.data.Data.Data
					if (res.data.Data.Data != null) {
						this.T_ish = res.data.Data.Data[0].T_DeviceSensorData.T_ish
						this.T_ist = res.data.Data.Data[0].T_DeviceSensorData.T_ist
					}
				}
				this.loadTable = false
			})
		},
		async navNumclick(index, item) { //点击导航
            console.log('点击导航',index,item)
			this.navNum = index
			this.descrData = item
			this.GetDevSenListApi(item)
			let res = await this.getDevGetApi(item)
		},
		getDevGetApi(obj) {
			return new Promise(resolve => {
				DevGet({ T_sn: obj.T_sn }).then(res => {
					if (res.data.Code === 200) {
						////console.log('大厅22ss ',res.data.Data.Data)
						this.descrData = res.data.Data.Data
					}
					resolve(res)
				})
			})
		},
		inputser(e) { //搜索
                let self = this
            clearTimeout(this.debounceTimer)
            this.debounceTimer = setTimeout(() => {
                self.navNum = 0
                self.pages.T_name = e
                self.pages.page = 1
                self.NavList = []
                self.GetDevListApi()
            }, 500);
		},


		setoff() {
			this.$confirm('此操作将关机或重启设备, 请选择操作', '提示', {
				distinguishCancelAndClose: true,
				confirmButtonText: '关机',
				cancelButtonText: '重启设备',
				type: 'warning',
				center: true,
			}).then(() => {
				// ////console.log('关机')
				this.setResDows(0)
			}).catch((e) => {
				if (e == 'cancel') {//close值是点击了X  cancel点击的取消
					this.setResDows(1)
				}
			});
		},
		setResDows(bl) {
			var that = this
			DevRes({
				T_sn: that.descrData.T_sn,
				T_task: bl
			}).then(res => {
				if (res.data.Code == 200) {
					if (bl == 0) {
						that.$message.success('操作成功，关机')
					} else {
						that.$message.success('操作成功，重启')
					}
				}
			})
		},

		async handleEdit(item) { //点击设置按钮
            console.log('点击设置',item)
            Object.keys(this.jointForm).forEach(key => {this.jointForm[key] = '';});//清空对象
            this.activeName1 = 'firsts'
			var perData1 = pertabs('DeviceSensor:Parameter')
			var perData2 = pertabs('DeviceSensor:Config')
            this.downArr = [false,false,false,false]
			if (perData1 != false || perData2 != false) {
				this.dialogVisible4 = true
			} else {
				this.$message.error('无权查看')
				return
			}
			this.$nextTick(() => {
				if (perData1 == false) {//等于true，有权限
					this.$refs.tabs1.$children[0].$refs.tabs[0].style.display = 'none'
					this.activeName1 = 'seconds'
				}
				if (perData2 == false) {//等于true，有权限
					this.$refs.tabs1.$children[0].$refs.tabs[1].style.display = 'none'
					this.activeName1 = 'firsts'
				}
			})
			this.getDevListAllApi()
            
			let {T_id,T_sn,T_sort,T_type,T_datashow,T_3dview,probeIdNo,T_name} = item

			this.pages2.T_id = T_id
			this.pages2.T_sn = T_sn

			this.diaForm2 = {
				T_id,T_sn,T_sort,T_type,T_datashow,T_3dview,probeIdNo,
                deviceName:T_name
			}
			this.getPaGetApi(T_name)
            if(this.$store.state.permission.includes('JWX:docking')){//解决金卫信对接项没有权限
                const {data:res} =  await findMaintainInfo({T_id,T_sn}) 
                if(res.Code==200){
                    let obj1 = res.Data || {}
                    if(Object.keys(obj1).length != 0){
                        for (let key in this.jointForm) {
                            this.jointForm[key] = obj1[key];
                        }
                    }
                    
                    console.log('点击设置', this.jointForm ,'obj1',obj1)
                    if(this.jointForm.sensorIdNo==''){//随便判断一个如果对象有空数据，就本地生成
                        this.jointForm.T_sn = T_sn
                        this.jointForm.probeIdNo = T_sn + '_' + T_id
                        this.jointForm.T_id = T_id
                        this.jointForm.deviceName = T_name

                        if(!this.jointForm.sensorIdNo){// 动态生成中继器网关序号
                            const now = new Date();
                            const year = now.getFullYear();
                            // const month = String(now.getMonth() + 1).padStart(2, '0');
                            this.jointForm.sensorIdNo = T_sn + '_' + year;
                        }
                        if(!this.jointForm.sensorName){
                            this.jointForm.sensorName = T_sn + '_ZJQ';
                        }
                        console.log('点击设置1', this.jointForm )
                    }
                }
            }
            
		},
		getPaGetApi(item) {//传感器参数获取数据
			DevParGet(this.pages2).then(res => {
				if (res.data.Code == 200) {
					console.log('传感器参数获取数据', res.data)
					this.diaForm1 = res.data.Data[0]
					// this.$set(this.diaForm1,'T_enprelnote',0)
					this.$set(this.diaForm1, 'T_devName', this.descrData.T_devName)
					this.$set(this.diaForm1, 'T_name', item)
				}
			})
		},
		Submit1() {
			console.log('diaForm1', this.diaForm1)
            console.log('123',this.downArr)
            if(this.downArr.includes(true)){
                this.$message.error('存在输入下限值大于上限值，请修改在提交')
                return
            }
			DevParParPu(this.diaForm1).then(res => {
				if (res.data.Code == 200) {
					this.dialogVisible4 = false
					this.$message.success('操作成功，传感器参数设置成功')
					this.pages.page = 1
					this.NavList = []
					this.GetDevListApi()
				}
			})
		},

		async Submit2() {
            console.log('diaForm2', this.diaForm2,this.descrData)
			const {data:res} = await DevSenEdit(this.diaForm2) 
            if (res.Code == 200) {
                this._isidno()
                
            }
		},

        _isidno(){
            this.dialogVisible4 = false
            this.$message.success('操作成功，传感器配置成功')
            this.pages.page = 1
            this.NavList = []
            this.GetDevListApi()
        },

		SetDevParameter_PuApi(obj) {
			DevParameter_Pu(obj).then(res => {
				if (res.data.Code === 200 && res.data.Msg === "ok!") {
					this.$message.success('修改成功')
					this.dialogVisible = false
					this.navNum = 0
					this.GetDevListApi()
				}
			})
		},


		SetNew() { //新增主机
			this.dialogShowinstr1 = true
			if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
				this.$refs.ruleForm.resetFields();
			}
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.GetDevAddApi()
				} else {
					return false;
				}
			});
		},

		GetDevAddApi() {
			DevAdd(this.ruleForm).then(res => {
				////console.log('新增', res)
				if (res.data.Code === 200 && res.data.Msg === "ok!") {
					this.$message.success('新增成功')
					this.pages.page = 1
					this.NavList = []
					this.GetDevListApi()
					this.dialogShowinstr1 = false
				}
			})
		},
		
		devices() { //设备管理弹窗
			this.activeName = 'first'
			this.dialogVisible = true
			this.ShowTishi = ''
			this.GetDevSenParameter_ListApi()
			this.pages1.T_sn = this.descrData.T_sn
			this.pages1.T_give = this.descrData.T_give
			this.pages1.T_l_p = this.descrData.T_l_p
		},


		instructionse() {
			this.dialogShowinstr = true
		},
	}
}
</script>
<style scoped lang="scss">
._jointStyle{
	width: 260px !important;
}


.el-divider--horizontal {
	display: block;
	height: 1px;
	width: 100%;
	margin: 0 0 24px 0;
}</style>
<style lang="scss">
@import '../../assets/css/equipmentManagement/management.scss';



</style>
